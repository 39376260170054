<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-card title="도급업체 평가 기본정보" class="cardClassDetailForm" topClass="topcolor-orange">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="결재요청" icon="save"/>
            <c-btn v-if="editable" label="평가완료" icon="save"/>
            <c-btn v-if="editable" label="저장" icon="save" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-plant
              :required="true"
              :editable="editable"
              type="edit"
              name="plantCd"
              v-model="vendorEval.plantCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-vendor
              :editable="editable"
              :required="true"
              label="평가업체"
              name="vendorCd"
              v-model="vendorEval.vendorCd">
            </c-vendor>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :required="true"
              :editable="editable"
              :range="true"
              label="평가기간"
              name="evalYearYmd"
              v-model="vendorEval.evalYearYmd">
            </c-datepicker>
          </div>
          <div class="col-12">
            <c-text
              :editable="editable"
              label="평가결과 종합"
              name="remark"
              v-model="vendorEval.remark">
            </c-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" topClass="topcolor-orange">
      <c-table
        ref="table"
        title="도급업체 평가 항목 목록"
          parentProperty="pid"
        customID="itemNo"
        :columns="grid.columns"
        :data="grid.data"
        :merge="grid.merge"
        :gridHeight="grid.height"
        cardClass="topcolor-orange"
        :usePaging="false"
        rowKey="itemSeq"
      >
        <template slot="table-chip">
          <q-btn-group outline >
            <q-chip outline square>
                <q-avatar icon="bookmark" color="orange" text-color="white" />
                {{vendorEval.totalGrade + " / " + vendorEval.evalToatlGrade + " / " + vendorEval.grade}}
                <q-tooltip>{{ "총점 / 평점 / 등급 " }}</q-tooltip>
            </q-chip>
            <!-- <q-chip
              :label="vendorEval.evalToatlGrade"
              color="orange"
              text-color="white"
            >
                <q-tooltip>{{ "평점" }}</q-tooltip>
            </q-chip>
            <q-chip
              :label="vendorEval.grade"
              color="orange"
              text-color="white"
            >
                <q-tooltip>{{ "등급" }}</q-tooltip>
            </q-chip> -->
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'vendor-assess-info',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          evaluationResultSeq: 0,
        }
      },
    },
  },
  data() {
    return {
      editable: true,
      vendorEval: {
        evaluationResultSeq: 0,
        plantCd: '1',
        vendorCd: '',
        vendorNm: '',
        seqNo: 0,
        evalYear: '',
        evalStartYmd: '2021-01-25',
        evalEndYmd: '2021-05-24',
        evalToatlGrade: '90점',
        totalGrade: '100점',
        grade: 'A',
        remark: '',
      },
      grid: {
        merge: [
          { index: 0, colName: 'evalTypeNm' },
        ],
        columns: [
          // 대안1
          // {
          //   name: "evalNm",
          //   field: "evalNm",
          //   label: "평가항목",
          //   align: "left",
          //   sortable: false,
          // },
          // {
          //   name: "points",
          //   field: "points",
          //   label: "배점",
          //   align: "center",
          //   sortable: false,
          // },
          // {
          //   name: "evalGrade",
          //   field: "evalGrade",
          //   label: "평점",
          //   type: "number",
          //   align: "center",
          //   sortable: false,
          //   disableTarget: 'evaTypeNm',
          //   disableCon: 2,
          // },
          // {
          //   name: "evalStandard",
          //   field: "evalStandard",
          //   label: "평가기준",
          //   align: "left",
          //   type: 'text',
          //   sortable: false,
          // },

          // 대안2
          {
            name: "evalTypeNm",
            field: "evalTypeNm",
            style: 'width:150px',
            label: "평가유형",
            align: "left",
            sortable: false,
          },
          {
            name: "evalClassNm",
            field: "evalClassNm",
            label: "평가구분",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: "evalNm",
            field: "evalNm",
            style: 'width:200px',
            label: "평가항목",
            align: "left",
            sortable: false,
          },
          {
            name: "points",
            field: "points",
            label: "배점",
            style: 'width:60px',
            align: "center",
            sortable: false,
          },
          {
            name: "evalGrade",
            field: "evalGrade",
            label: "평점",
            style: 'width:60px',
            type: "number",
            align: "center",
            sortable: false,
          },
          {
            name: "evalStandard",
            field: "evalStandard",
            label: "평가기준",
            type: "text",
            style: 'width:200px',
            align: "left",
            sortable: false,
          },
        ],
        data: [],
        height: '500px'
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.getList();
    },
    getList() {
      this.grid.data = [
        // 대안1
        // {
        //   evaTypeNm: 1,
        //   evalNm: '1. 안전에 관한 사항 (안전관리부서 평가부분)',
        //   evalStandard: '',
        //   points: '',
        //   evalGrade: '',
        // },
        // {
        //   evaTypeNm: 2,
        //   evalNm: '안전관리계획서 작성/평가',
        //   evalStandard: '평가기준1',
        //   points: '4',
        //   evalGrade: '',
        // },{
        //   evaTypeNm: 2,
        //   evalNm: '폐기물 분리 및 처리상태',
        //   evalStandard: '평가기준1',
        //   points: '4',
        //   evalGrade: '',
        // },
        // {
        //   evaTypeNm: 2,
        //   evalNm: '안전보호구 착용상태',
        //   evalStandard: '평가기준2',
        //   points: '4',
        //   evalGrade: '',
        // },{
        //   evaTypeNm: 2,
        //   evalNm: '대표자 및 현장 책임자의 안전관리 능력',
        //   evalStandard: '평가기준3',
        //   points: '4',
        //   evalGrade: '',
        // },{
        //   evaTypeNm: 1,
        //   evalNm: '2. 작업에 관한 사항(생산브서 또는 공사감독부서 평가부분)',
        //   evalStandard: '',
        //   points: '',
        //   evalGrade: '',
        // },{
        //   evaTypeNm: 2,
        //   evalNm: '작업관련 서류 처리 능력',
        //   evalStandard: '평가기준4',
        //   points: '4',
        //   evalGrade: '',
        // },{
        //   evaTypeNm: 2,
        //   evalNm: '작업계획능력',
        //   evalStandard: '평가기준5',
        //   points: '5',
        //   evalGrade: '',
        // },
        // {
        //   evaTypeNm: 2,
        //   evalNm: '작업지시의 순응도',
        //   evalStandard: '평가기준6',
        //   points: '4',
        //   evalGrade: '',
        // },
        // {
        //   evaTypeNm: 2,
        //   evalNm: '납기 준수 여부',
        //   evalStandard: '평가기준7',
        //   points: '3',
        //   evalGrade: '',
        // },

        // 대안2
        {
          evalTypeNm: '1. 안전에 관한 사항 (안전관리부서 평가부분)',
          evalNm: '안전관리계획서 작성/평가',
          evalStandard: '평가기준1',
          points: '4',
          evalGrade: '',
        },{
          evalTypeNm: '1. 안전에 관한 사항 (안전관리부서 평가부분)',
          evalNm: '폐기물 분리 및 처리상태',
          evalStandard: '평가기준1',
          points: '4',
          evalGrade: '',
        },
        {
          evalTypeNm: '1. 안전에 관한 사항 (안전관리부서 평가부분)',
          evalNm: '안전보호구 착용상태',
          evalStandard: '평가기준2',
          points: '4',
          evalGrade: '',
        },{
          evalTypeNm: '1. 안전에 관한 사항 (안전관리부서 평가부분)',
          evalNm: '대표자 및 현장 책임자의 안전관리 능력',
          evalStandard: '평가기준3',
          points: '4',
          evalGrade: '',
        },{
          evalTypeNm: '2. 작업에 관한 사항(생산브서 또는 공사감독부서 평가부분)',
          evalNm: '작업관련 서류 처리 능력',
          evalStandard: '평가기준4',
          points: '4',
          evalGrade: '',
        },{
          evalTypeNm: '2. 작업에 관한 사항(생산브서 또는 공사감독부서 평가부분)',
          evalNm: '작업계획능력',
          evalStandard: '평가기준5',
          points: '5',
          evalGrade: '',
        },
        {
          evalTypeNm: '2. 작업에 관한 사항(생산브서 또는 공사감독부서 평가부분)',
          evalNm: '작업지시의 순응도',
          evalStandard: '평가기준6',
          points: '4',
          evalGrade: '',
        },
        {
          evalTypeNm: '2. 작업에 관한 사항(생산브서 또는 공사감독부서 평가부분)',
          evalNm: '납기 준수 여부',
          evalStandard: '평가기준7',
          points: '3',
          evalGrade: '',
        },
      ]
    },
    reset() {
      Object.assign(this.$data.vendorEval, this.$options.data().vendorEval);
    },
  }
};
</script>